import React, { useState, useCallback, useEffect, ReactText } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IStateFilterTechDecision, IUnc } from '@root-gipro/store/interfaces'
import { RangePriceFilter } from '@root-gipro/modules/commonProjects/components'
import { setFilterCommonProjectsRates, resetFilterCommonProjectsRates } from '@root-gipro/modules/filter/store/actions'
import { Select } from '@root-gipro/shared/FormElements'
import { IState } from '@root-gipro/store/interfaces/state'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import { IFilterRates } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'
export interface RatesFilter {
	uncs: IUnc[]
	setFilteredRates(uncs: IUnc[]): void
	setAnchorEl(event: HTMLElement): void
	anchorEl: (EventTarget & HTMLElement) | null | undefined
}

const RatesFilter: React.FC<RatesFilter> = ({ uncs, setFilteredRates, anchorEl, setAnchorEl }) => {
	const { commonProjectsRates } = useSelector((state: IState) => state.filter)
	const [state, setState] = useState(commonProjectsRates)
	const dispatch = useDispatch()

	const resetFilter = useCallback(() => {
		dispatch(resetFilterCommonProjectsRates())
		setFilteredRates(null!)
	}, [commonProjectsRates])

	const handleCloseFilter = () => {
		setAnchorEl(null!)
	}

	useEffect(() => {
		setState(commonProjectsRates)
	}, [commonProjectsRates])

	const changeFilter = (filterKey: keyof IStateFilterTechDecision, value: string[] | ReactText) => {
		setState((prevState: IFilterRates) => ({ ...prevState, [filterKey]: value }))
	}

	const uncThsNames = uncs.map(unc => unc.uncParameter.thName).filter(unc => unc)
	const paramsName = uncs.map(unc => unc.uncCell.parameter).filter(unc => unc)
	const voltageValues = uncs.map(unc => unc.uncCell.voltageValue).filter(unc => unc)
	const uncMainTables = uncs.map(unc => unc.uncMain.code).filter(unc => unc)

	const uncMainColumns = uncs
		.map(unc => unc.uncMain.colsCnt)
		.filter(i => i !== 0)
		.sort((a, b) => a - b)
		.map(i => i.toString())

	const uncMainRows = (): string[] => {
		const arrNumbers = uncs.map(unc => unc.uncMain.rowsCnt)
		const maxNumber = Math.max(...(arrNumbers ?? []))
		return Array.from({ length: maxNumber }, (_, i) => (i + 1).toString())
	}

	let data: IUnc[] = uncs

	if (!!state.name.length) {
		data = data.filter((unc: IUnc) => state.name.includes(unc.uncParameter.thName))
	}
	if (!!state.countStart) {
		data = data.filter((unc: IUnc) => +state.countStart <= unc.count)
	}
	if (!!state.countEnd) {
		data = data.filter((unc: IUnc) => +state.countEnd >= unc.count)
	}
	if (!!state.params.length) {
		data = data.filter((unc: IUnc) => state.params.includes(unc.uncCell.parameter))
	}
	if (!!state.voltage.length) {
		data = data.filter((unc: IUnc) => state.voltage.includes(unc.uncCell.voltageValue))
	}
	if (!!state.table.length) {
		data = data.filter((unc: IUnc) => state.table.includes(unc.uncMain.code))
	}
	if (!!state.row.length) {
		data = data.filter((unc: IUnc) => state.row.includes(unc.uncMain.rowsCnt.toString()))
	}
	if (!!state.column.length) {
		data = data.filter((unc: IUnc) => state.column.includes(unc.uncMain.colsCnt.toString()))
	}
	const title = [
		{
			classes: 'leftTitle',
			value: 'Тех. решения',
			action: undefined,
		},
		{
			classes: 'rightTitleBlue',
			value: 'Сбросить',
			action: resetFilter,
		},
	]
	const buttons = {
		PrimaryBtn: {
			handler: () => {
				dispatch(setFilterCommonProjectsRates(state))
				setFilteredRates(data)
			},
			value: 'Готово',
		},

		SecondaryBtn: {
			handler: handleCloseFilter,
			value: 'Отмена',
		},
	}
	return (
		<>
			<Modal buttonOptions={buttons} setAnchorEl={setAnchorEl} anchorEl={anchorEl} title={title}>
				<div className='rates-filter'>
					<div className='aside-filter__input'>
						<Select
							value={state.name}
							setValue={changeFilter}
							placeholder='Наименование'
							fieldName={'name'}
							isMultiple={true}
							items={uncThsNames}
						/>
					</div>
					<div className='aside-filter__input'>
						<Select
							value={state.params}
							setValue={changeFilter}
							placeholder='Параметры'
							fieldName={'params'}
							isMultiple={true}
							items={paramsName}
						/>
					</div>
					<div className='aside-filter__input'>
						<Select
							value={state.voltage}
							setValue={changeFilter}
							placeholder='Напряжение, кВ'
							fieldName={'voltage'}
							isMultiple={true}
							items={voltageValues ?? []}
						/>
					</div>
					<div className='aside-filter__input'>
						<Select
							value={state.table}
							setValue={changeFilter}
							placeholder='Таблица'
							fieldName={'table'}
							isMultiple={true}
							items={uncMainTables ?? []}
						/>
					</div>
					<div className='aside-filter__input'>
						<Select
							value={state.row}
							setValue={changeFilter}
							placeholder='Строка'
							fieldName={'row'}
							isMultiple={true}
							items={uncMainRows() ?? []}
						/>
					</div>
					<div className='aside-filter__input'>
						<Select
							value={state.column}
							setValue={changeFilter}
							placeholder='Столбец'
							fieldName={'column'}
							isMultiple={true}
							items={uncMainColumns ?? []}
						/>
					</div>
					<div className='aside-filter__input'>
						<RangePriceFilter
							setStartPrice={(value: string | number) => changeFilter('countStart', value)}
							setEndPrice={(value: string | number) => changeFilter('countEnd', value)}
							startPrice={state.countStart}
							endPrice={state.countEnd}
							title={'Количество'}
							startText={'с'}
							endText={'по'}
						/>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default RatesFilter
